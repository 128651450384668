<template lang="pug">
transition(name="slide-fade")
  .home
    loading(:active.sync="busy", :is-full-page="true")

    b-tabs
      b-tab(title="DataLocker")
        .widget
          .widget-body
            .ov-table-wrapper
              table.table.table-bordered
                thead
                  tr
                    th ID
                    th Date
                    th Actions
                tbody
                  tr(v-if="dlReports.length === 0")
                    td(colspan="3") No matching records were found
                  tr(v-for="r in dlReports", v-bind:key="r.id")
                    td {{ r.id }}
                    td {{ r.date }}
                    td.actions
                      .action-buttons
                        span.btn.btn-action-info(v-b-tooltip.hover.bottom, title="Download report")
                          a.no-link.text-white(v-if="r.file_path", @click="downloadReport(r)")
                            i.la.la-download
            paginate(:paginator="dlPaginate", @update-page-size="loadDlData")
      b-tab(title="Post-Attribution")
        .widget
          .widget-body
            .ov-table-wrapper
              table.table.table-bordered
                thead
                  tr
                    th ID
                    th File Name
                    th Date
                    th Status
                    th Error
                    th Actions
                tbody
                  tr(v-if="paReports.length === 0")
                    td(colspan="3") No matching records were found
                  tr(v-for="r in paReports", v-bind:key="r.id")
                    td {{ r.id }}
                    td {{ r.file_name }}
                    td {{ r.date }}
                    td
                      span.badge.badge-secondary(v-if="r.status === 'new'") New
                      span.badge.badge-primary(v-if="r.status === 'process'") Process
                      span.badge.badge-danger(v-if="r.status === 'error'") Error
                      span.badge.badge-success(v-if="r.status === 'done'") Done
                    td
                      .error-message {{ r.error_message }}
                    td.actions
                      .action-buttons
                        span.btn.btn-action-info(v-b-tooltip.hover.bottom, title="Download report")
                          a.no-link.text-white(v-if="r.file_path", :href="r.file_path", target="_blank")
                              i.la.la-download
            paginate(:paginator="paPaginate")
</template>
<style lang="scss" scoped>
.badge {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: normal;
}
</style>
<script>
import { downloadFile } from '../../lib/files';

export default {
  name: 'MmpAccountReports',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.mmpAccountId = to.query.mmp_account_id;
      return vm;
    });
  },
  data() {
    return {
      busy: false,

      mmpAccountId: null,

      dlReports: [],
      dlPaginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadDlData();
        },
      },

      paReports: [],
      paPaginate: {
        numPages: 0,
        total: 0,
        page: 1,
        limit: 50,
        onPageChange: () => {
          this.loadPaData();
        },
      },
    };
  },
  methods: {
    async loadDlData() {
      this.busy = true;
      const params = {
        page: this.dlPaginate.page,
        page_size: this.dlPaginate.limit,
        mmp_account_id: this.mmpAccountId,
      };
      try {
        const resp = await this.$ovReq.get('mmpAccount/getReportList', { params });
        this.dlReports = resp.records;
        this.dlPaginate.total = resp.total;
        this.dlPaginate.numPages = Math.max(
          Math.ceil(this.dlPaginate.total / this.dlPaginate.limit),
          1,
        );
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    async loadPaData() {
      this.busy = true;
      const params = {
        page: this.paPaginate.page,
        page_size: this.paPaginate.limit,
        mmp_account_id: this.mmpAccountId,
      };
      try {
        const resp = await this.$ovReq.get('click/pa-report/getList', { params });
        this.paReports = resp.records;
        this.paPaginate.total = resp.total;
        this.paPaginate.numPages = Math.max(
          Math.ceil(this.paPaginate.total / this.paPaginate.limit),
          1,
        );
      } catch (e) {
        console.error(e);
      }
      this.busy = false;
    },

    /**
     * Download report using blob url
     * @param report 
     */
    async downloadReport(report) {
			const filename = this.generateFileName(report);
			try {
				await downloadFile(report.file_path, filename);
      } catch (e) {
        this.$ovNotify.error('Failed to download report');
        return;
      }
    },

    generateFileName(report) {
      const originalExtension = report.file_path.split('.').pop();
      return `${report.mmp_account_id}_${report.date}.${originalExtension}`;
    },
  },
  async mounted() {
    await Promise.all([this.loadDlData(), this.loadPaData()]);
  },
};
</script>
